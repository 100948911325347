.detial-category {
    height: 100vh;

}
.detial {
    /* margin-top: 15px; */
    display: flex;
    /* height: 100vh; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.img-3 {
    width: 700px;
    height: 500px;
    border-radius: 20px;
    margin-top: 20px;
}

.p-content {
    color: rgb(92, 92, 92);
    font-size: large;
    font-weight: 500;
}

h1{
    color: green;
}

.img_detial {
    display: grid;
    grid-template-columns: repeat(2, 1);
}
.desc{
    display: flex;
    text-align: justify;
}
.garchig{
    display: flex;
    align-self: center;
    padding: 20px;
    padding-left: 5rem;
    padding-right: 5rem;
    background-color: #27ae5f2c;
    border-radius: 15px;
    margin-top: 10px;
}
@media screen and (max-width: 800px) {
    /* .detial-category {
        margin-top: 55rem;
        margin-bottom: 50rem;
    } */

    .detial{
        margin: 0 20px;
    }
    .img-3 {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        padding-bottom: 50px;
    }
  }